<template>
	<div class="notes-wrapper card">
		<div class="card-body">
			<h2>May 2022</h2>
			<div class="release-link">
				<h3 class="mb-0">
					<router-link :to="{ name: 'releasenotes', params: { version: '1.5.1' } }">Version 1.5.1 </router-link>
				</h3>
			</div>
			<div class="release-link">
				<h3 class="mb-0">
					<router-link :to="{ name: 'releasenotes', params: { version: '1.5.0' } }">Version 1.5.0 </router-link>
				</h3>
			</div>
		</div>
		<!-- <ReleaseNotesContainer :id="1" open>
			<template v-slot:version> Version 1.1 - July 7th, 2021</template>
			<template v-slot:notes>
				<h4>Improvement</h4>
				<ul class="ml-15">
					<li>[VPF-69] - Dashboard - CSV download for Total Bandwidth widget</li>

					<li>[VPF-158] - DashStats links covered</li>

					<li>[VPF-173] - Dashboard - Site Usage - bootstrap chart</li>

					<li>[VPF-175] - Dashboard - Network total bandwidth calc on backend</li>

					<li>[VPF-193] - Site - Daily usage respond to date range</li>

					<li>[VPF-194] - Site - iDirect Graph - show interface on sat traffic also</li>

					<li>[VPF-207] - Site - Exinda Widget - add note that data is 24 hours only</li>
				</ul>

				<h4>Task</h4>
				<ul class="ml-15">
					<li>[VPF-204] - Admin - Edit Account - remove Show Linecards</li>
				</ul>

				<h4>New Feature</h4>
				<ul class="ml-15">
					<li>[VPF-40] - Site - Peplink</li>

					<li>[VPF-187] - Site - Solarwinds - add link to solarwinds interface</li>

					<li>[VPF-195] - Site - Tickets/add ticket</li>

					<li>[VPF-216] - NOC Alerts - Linecards</li>
				</ul>

				<h4>Bug</h4>
				<ul class="ml-15">
					<li>[VPF-108] - Site - Solarwinds show message when no data</li>

					<li>[VPF-121] - Admin - Edit site - Linked Accounts</li>

					<li>[VPF-129] - Site - error loading</li>

					<li>[VPF-131] - Admin - Edit Site - long site name issue</li>

					<li>[VPF-149] - Site Idirect Graph MODCOD label smooshed</li>

					<li>[VPF-167] - Site - iDirect graph, says IP Traffic twice</li>

					<li>[VPF-178] - Site - Realtime and NOC buttons showing always</li>

					<li>[VPF-180] - Admin - Edit site - Edit widget props Solarwinds doesn't save</li>

					<li>[VPF-181] - Admin - Edit Account - Show API tab missing</li>

					<li>[VPF-188] - Admin - Edit Site - Layout not showing</li>

					<li>[VPF-190] - Site Solarwinds interface data off by a bit</li>

					<li>[VPF-200] - Site - Map -Handle missing spacecraft property</li>

					<li>[VPF-202] - Admin - Edit Accnount - dash layout not updated</li>

					<li>[VPF-203] - Client API - fix logo in swagger docs</li>

					<li>[VPF-212] - Site - Solarwinds Packet Loss not showing</li>
				</ul>
			</template>
		</ReleaseNotesContainer>
		<ReleaseNotesContainer :id="0">
			<template v-slot:version> Version 1.0 - April 6th, 2021</template>
			<template v-slot:notes>
				<h4>Improvement</h4>
				<ul class="ml-15">
					<li>[VPF-67] - Site - IP SLA Averages</li>

					<li>[VPF-89] - Admin - Edit Site - layout</li>

					<li>[VPF-90] - Dashboard - Site List device icons</li>

					<li>[VPF-91] - Site - iDirect Graph - Show MIR/CIR line</li>

					<li>[VPF-92] - Site - Idirect Graph - Download CSV</li>

					<li>[VPF-93] - Site - Map - Download GPS path as CSV</li>

					<li>[VPF-94] - Header - Voip dropdown - show site name link if exists</li>

					<li>[VPF-96] - Site - Map - update Map range on data time picker</li>

					<li>[VPF-98] - Dashboard - IP SLA Latency widget properties</li>

					<li>[VPF-100] - Admin - render admin components based on user's role</li>

					<li>[VPF-101] - Admin - Edit account - left side bar</li>

					<li>[VPF-111] - Admin - New Account - handle can_view checkboxes</li>

					<li>[VPF-112] - Dashboard - Use Layout</li>

					<li>[VPF-118] - Add HardSourceWebpackPlugin cache</li>

					<li>[VPF-128] - Admin - Edit site - name length validation</li>

					<li>[VPF-135] - Adjust StickyToolbar</li>

					<li>[VPF-145] - NOC Alerts only needs to show on Admin Pages</li>

					<li>[VPF-155] - Dashboard - Site List - sort by modem status</li>

					<li>[VPF-159] - SRF - Add Save as New</li>
				</ul>

				<h4>Task</h4>
				<ul class="ml-15">
					<li>[VPF-123] - SiteMap - move beamchange icon</li>

					<li>[VPF-141] - Remove Search in top bar</li>
				</ul>

				<h4>New Feature</h4>
				<ul class="ml-15">
					<li>[VPF-1] - Login</li>

					<li>[VPF-2] - Login - stretch carousel images to full screen</li>

					<li>[VPF-3] - Admin - Main page</li>

					<li>[VPF-4] - Admin - Main page account list</li>

					<li>[VPF-5] - Admin - Main page site list</li>

					<li>[VPF-6] - Admin - Main page devices</li>

					<li>[VPF-7] - Admin - Main page change log</li>

					<li>[VPF-8] - Admin - edit account</li>

					<li>[VPF-9] - Admin - Edit Site</li>

					<li>[VPF-11] - Admin - New account</li>

					<li>[VPF-12] - Admin - New site</li>

					<li>[VPF-13] - Admin - NOC Alert - Emailed Alerts</li>

					<li>[VPF-15] - Dashboard - Main</li>

					<li>[VPF-16] - Dashboard - Bandwidth networks</li>

					<li>[VPF-17] - Dashboard - CRC</li>

					<li>[VPF-18] - Dashboard - IP SLA Availability</li>

					<li>[VPF-19] - Dashboard - IP SLA Latency</li>

					<li>[VPF-20] - Dashboard - Map</li>

					<li>[VPF-22] - Dashboard - Site List</li>

					<li>[VPF-23] - Dashboard - Site usage</li>

					<li>[VPF-24] - Dashboard - Tickets</li>

					<li>[VPF-25] - Edit Profile</li>

					<li>[VPF-26] - Realtime</li>

					<li>[VPF-27] - Site</li>

					<li>[VPF-28] - Site - Exinda</li>

					<li>[VPF-29] - Site - Map</li>

					<li>[VPF-30] - Site - Idirect 24 hour usage</li>

					<li>[VPF-31] - Site - Beam switches</li>

					<li>[VPF-32] - Site - iDirect details</li>

					<li>[VPF-33] - Site - iDirect graphs</li>

					<li>[VPF-34] - Site - iDirect heat map</li>

					<li>[VPF-35] - Site - iDirect IP Info</li>

					<li>[VPF-36] - Site - iDirect modem status</li>

					<li>[VPF-37] - Site - Solar transit</li>

					<li>[VPF-38] - Site - iDirect usage by protocol</li>

					<li>[VPF-41] - Site - Quota</li>

					<li>[VPF-43] - Site - Solarwinds Availability</li>

					<li>[VPF-44] - Site - Solarwinds graph</li>

					<li>[VPF-47] - Site - Solarwinds Netflow</li>

					<li>[VPF-48] - Site - Solarwinds 4G</li>

					<li>[VPF-49] - Site - Solarwinds Comtech</li>

					<li>[VPF-50] - Site - Solarwinds IP SLA</li>

					<li>[VPF-51] - Site - TSIP</li>

					<li>[VPF-52] - Ticket Details</li>

					<li>[VPF-53] - Login/Auth</li>

					<li>[VPF-54] - Upgrade Metronic to v7</li>

					<li>[VPF-58] - Tickets Page</li>

					<li>[VPF-59] - View As functionality</li>

					<li>[VPF-60] - Site List - Graphs</li>

					<li>[VPF-62] - Site - Solarwinds Daily Graph</li>

					<li>[VPF-64] - Site - Voip</li>

					<li>[VPF-71] - Site - Comtech Solar Transit</li>

					<li>[VPF-72] - Site -Comtech Map</li>

					<li>[VPF-74] - Date Range Selector</li>

					<li>[VPF-75] - Layout.vue hide headers option</li>

					<li>[VPF-76] - Realtime - Modem status modal</li>

					<li>[VPF-77] - Realtime - Download Option file</li>

					<li>[VPF-78] - SRF - List</li>

					<li>[VPF-83] - Site - Idirect Graphs - IP SLA on Latency Tab</li>

					<li>[VPF-87] - Notification store</li>

					<li>[VPF-88] - Consolidate User Info in store</li>

					<li>[VPF-163] - Site List Graphs page</li>
				</ul>

				<h4>Bug</h4>
				<ul class="ml-15">
					<li>[VPF-66] - SolarwindsGraph doesn't match old portal</li>

					<li>[VPF-80] - Realtime button covered on IdirectModemStatus.vue when half size</li>

					<li>[VPF-82] - Admin Accounts List - responsive</li>

					<li>[VPF-102] - Dashboard - built project not showing number in top blocks</li>

					<li>[VPF-103] - Dashboard - Site Usage bars too fat</li>

					<li>[VPF-104] - Don't show admin page selector on Profile for user role</li>

					<li>[VPF-106] - Clear vuex on logout</li>

					<li>[VPF-107] - ApiService - Error loop on 401 from api</li>

					<li>[VPF-109] - Admin - Create new account error messsage</li>

					<li>[VPF-110] - Admin - Account List - icon coming out of box</li>

					<li>[VPF-113] - Dashboard - IP SLA Availability not showing</li>

					<li>[VPF-114] - Admin - Edit site - spinner issues</li>

					<li>
						[VPF-116] - Admin - Edit Site - Should say Show Quota Alert when hidden but Quota is set
					</li>

					<li>[VPF-117] - Admin - Edit Site - Widget props icon not changing right after edit</li>

					<li>[VPF-119] - Admin - Searching a small number of accounts shrinks site window</li>

					<li>
						[VPF-124] - Admin - Edit account- changing account parent changes left side profile box
					</li>

					<li>[VPF-125] - add idirect graph formatters</li>

					<li>[VPF-133] - Admin - Edit Account - view as user not working</li>

					<li>[VPF-137] - Admin - Edit Site - Remove All Accounts</li>

					<li>[VPF-138] - Links to NOC need full url</li>

					<li>[VPF-139] - Ticket ID in TicketsList not linking to ticket</li>

					<li>[VPF-140] - Site - Need spinner when clicking apply to change date range</li>

					<li>[VPF-142] - Site - Idirect Graph - Download CSV - file missing CSV extension</li>

					<li>[VPF-146] - Dashboard - Network total bandwidth out of order</li>

					<li>[VPF-147] - Login Loop</li>

					<li>[VPF-157] - SRF List not loading for SpeedcastGovt</li>

					<li>
						[VPF-161] - Admin Edit Site - edit layout properties of idirect tabbed widget, no defaults
					</li>

					<li>[VPF-165] - Dashboard - tickets spinner forever when no tickets</li>

					<li>[VPF-171] - SRF - Links in emails not working</li>
				</ul>
			</template>
		</ReleaseNotesContainer> -->
	</div>
</template>

<script>
//  remove "open" from old ReleaseNotesContainer
//
//  get Jira release notes in text format and paste, then:
//
// replace:
// \*\*(.*)$
// </ul>\n\n<h4>$1</h4>\n<ul class="ml-15">\n
// REMOVE first </ul>
//
//
//
// replace:
// ^\s+\*(.*)$
//     <li>$1</li>\n
//
// add last </ul>

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { mapGetters } from 'vuex';
export default {
	name: 'ReleaseNotes',
	components: {
		ReleaseNotesContainer: () => import('@/view/content/widgets/admin/ReleaseNotesContainer.vue'),
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	created() {
		if (this.currentUser.role !== 'admin') {
			this.$router.push({ name: 'dash' });
		}
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Release Notes' }]);
	},
};
</script>

<style scoped>
.release-link {
	border-top: 1px solid rgba(99, 99, 99, 0.3);
	display: flex;
	align-items: center;
	padding: 10px;
}
.release-link:last-child {
	border-bottom: 1px solid rgba(99, 99, 99, 0.3);
}
</style>
